.footer-pics {
    height: 50px;
    margin-right: 20px; 
    color:white !important;
    text-decoration: none;
}

.App-footer {
    background-color: #29293C;
    height: 260px;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.App-footer-big{
    font-size: 24px;
    font-weight: bold;
    padding-top: 50px;
}

.social-images{
    padding: 25px; 
}
