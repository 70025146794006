.form-control {
  border-radius: 5px;
  box-shadow: #707070;
}

.button.btnStyle {
  background-color: rgb(255, 255, 255);
  border: 4px #FBC500 solid;
  color: "#000000";
  height: "40px";
  width: "160px";
}
