.main-title {
  display: flex;
  padding: 50px;
  font-weight: 700;
  color: #000000;
}

.apply-logo-css-filter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.7;
}

.apply-logo-css-filter:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 0.9;
}