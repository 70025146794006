.quote-text{
    font-style: Segoe UI;
    font-size: 36px;
    font-weight: normal;
}

.vision-title{
    font-style: Segoe UI;
    font-size: 24px;
    font-weight: bold;
}

.vision-left-text{
    font-style: Segoe UI;
    font-size: 36px;
    font-weight: normal;
    float: left;
}

.vision-right-text{
    font-style: Segoe UI;
    font-size: 24px;
    font-weight: normal;
    float: right;
}

.visual-visual-element-left{
    height: 300px;
    width: 300px;
    background-color: #fff;
    border-radius: 50%;
    font-size: 50px;
    text-align: center;
    float: left;
}

.visual-right-text{
    font-style: Segoe UI;
    font-size: 30px;
    font-weight: normal;
    float: right;
}

.mission-visual-left{
    height: 370px;
    width: 370px;
    background-color: #010101;
    font-size: 50px;
    color: #fff;
    text-align: center;
}

.mission-title-right{
    font-style: Segoe UI;
    font-size: 30px;
    font-weight: normal;
    float: right;
}

.mission-text-right{
    font-style: Segoe UI;
    font-size: 30px;
    font-weight: normal;
    float: right;
}

.message-text{
    font-style: Segoe UI;
    font-size: 28px;
    font-weight: normal;
    text-align: center;
}

.bio {
    margin: 25px;
    font-family: "Lato", sans-serif;
    width: 700px;
    padding: 40px 25px;
    background: #d0e6e8;
    font-size: 18px;
    width: 550px;
}

.bioPic {
    margin: auto;
    display: block;
    height: 180px;
    width: 180px;
}

.bioWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bioTextWrapper {
    text-align: center;
    margin-top: 32px;
}

.bioName {
    font-size: 24px;
    font-weight: bold;
}

.bioRole {
    font-size: 16px;
}

.bioQuote {
    font-size: 24px;
    margin-top: 24px;
}

.bioText {
    font-size: 16px;
}